import React from "react";
import "./style.css";

export const Homepage = () => {
  return (
    <div className="homepage">
      <div className="div">
        <footer className="footer">
          <div className="copyright">
            <img className="line" alt="Line" src="/img/line.svg" />
            <p className="copyright-reco-co">
              <span className="text-wrapper">Copyright © </span>
              <span className="span">Reco-co</span>
              <span className="text-wrapper"> | Designed by </span>
              <span className="span">Joao Da Silva </span>
              <span className="text-wrapper"> - Powered by </span>
              <span className="span">Agartha Labs</span>
            </p>
          </div>
          <div className="utility-pages">
            <div className="text-wrapper-2"> Pages</div>
            <p className="style-guide-not">
              Style Guide <br />
              404 Not Found <br />
              Password Protected <br />
              Licences <br />
              Changelog
            </p>
          </div>
          <img className="img" alt="Line" src="/img/line-2.svg" />
          <div className="about">
            <div className="logo">
              <img className="logo-2" alt="Logo" src="/img/logo-4.png" />
              <img className="logo-3" alt="Logo" src="/img/logo-3.svg" />
            </div>
            <div className="content">
              <p className="connecter-les">
                Connecter les consommateurs&nbsp;&nbsp;les agriculteurs et producteurs locaux.
              </p>
              <div className="follow">
                <div className="element">
                  <img className="insta" alt="Insta" src="/img/insta.png" />
                </div>
                <div className="element">
                  <img className="fb" alt="Fb" src="/img/fb.png" />
                </div>
                <div className="element">
                  <img className="twiter" alt="Twiter" src="/img/twiter.png" />
                </div>
                <div className="element">
                  <img className="pintrest" alt="Pintrest" src="/img/pintrest.png" />
                </div>
              </div>
            </div>
          </div>
          <img className="line-2" alt="Line" src="/img/line-1.svg" />
          <div className="contact-us">
            <div className="text-wrapper-2">Contact Us</div>
            <p className="email-help-reco-co">
              <span className="span">
                Email
                <br />
              </span>
              <span className="text-wrapper-3">
                help@reco-co.ch
                <br />
                <br />{" "}
              </span>
              <span className="span">
                Phone
                <br />
              </span>
              <span className="text-wrapper-3">
                +147 565 35 30
                <br />
                <br />{" "}
              </span>
              <span className="span">
                Address
                <br />
              </span>
              <span className="text-wrapper-3">9 rue de Berne, Genève 1201, CH</span>
            </p>
          </div>
        </footer>
        <div className="overlap">
          <div className="offer">
            <div className="newslatteer">
              <div className="content-2">
                <div className="heading">
                  <h1 className="subscribe-to-our">
                    Subscribe to
                    <br />
                    our Newsletter
                  </h1>
                </div>
                <div className="button">
                  <div className="mail">
                    <div className="overlap-group">
                      <div className="text-wrapper-4">Your Email Address</div>
                    </div>
                  </div>
                  <div className="read-more-wrapper">
                    <div className="read-more">
                      <div className="text-wrapper-5">Subscribe</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="email-form">
            <div className="form">
              <div className="element-2">
                <div className="company">
                  <div className="div-wrapper">
                    <div className="text-wrapper-6">yourcompany name here</div>
                  </div>
                  <div className="text-wrapper-7">Company*</div>
                </div>
                <div className="div-2">
                  <div className="div-wrapper">
                    <div className="text-wrapper-6">how can we help</div>
                  </div>
                  <div className="text-wrapper-7">Subject*</div>
                </div>
              </div>
              <div className="element-3">
                <div className="p-wrapper">
                  <p className="text-wrapper-6">hello there,i would like to talk about how to...</p>
                </div>
                <div className="text-wrapper-8">Message*</div>
              </div>
              <div className="element-2">
                <div className="full-name">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-2">
                      <div className="text-wrapper-6">Your Email Address</div>
                    </div>
                  </div>
                  <div className="text-wrapper-8">Full Name*</div>
                </div>
                <div className="div-2">
                  <div className="div-wrapper">
                    <div className="text-wrapper-6">example@yourmail.com</div>
                  </div>
                  <div className="text-wrapper-8">Your Email*</div>
                </div>
              </div>
            </div>
          </div>
          <div className="element-4">
            <div className="text">
              <div className="text-wrapper-9">Default Button</div>
              <div className="aerrow">
                <img className="vector" alt="Vector" src="/img/vector.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-2">
          <div className="overlap-wrapper">
            <div className="overlap-3">
              <img className="background" alt="Background" src="/img/background-1.svg" />
              <div className="image" />
              <div className="gallery">
                <div className="gallery-2">
                  <div className="element-5">
                    <div className="text-2">
                      <p className="p">
                        <span className="text-wrapper-10">
                          Avantages pour les agriculteurs
                          <br />
                        </span>
                        <span className="text-wrapper-11">
                          <br />- Élargissement de la clientèle et accès à de nouveaux marchés.
                          <br />
                          <br />- Promotion directe des produits auprès des consommateurs.
                          <br />
                          <br />- Gestion des aspects logistiques tels que les commandes, les paiements et la livraison.
                          <br />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="element-6">
                    <div className="text-2">
                      <p className="avantages-pour-les">
                        <span className="text-wrapper-10">
                          Avantages pour les consommateurs
                          <br />
                        </span>
                        <span className="text-wrapper-11">
                          <br />- Accès facile à une large sélection de produits frais et locaux.
                          <br />
                          <br />- Contribution à une consommation responsable et durable.
                          <br />
                          <br />- Soutien aux agriculteurs locaux et à l&#39;économie locale.
                          <br />
                          <br />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="element-7">
                    <div className="text-2">
                      <p className="p">
                        <span className="text-wrapper-10">
                          Engagement envers la qualité et la transparence
                          <br />
                        </span>
                        <span className="text-wrapper-11">
                          <br />
                          <br />- Garantie de produits de qualité issus de sources fiables.
                          <br />
                          <br />- Transparence sur l&#39;origine des produits et les pratiques agricoles utilisées.
                          <br />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img className="group" alt="Group" src="/img/group-4.png" />
            </div>
          </div>
          <div className="why-choos-us">
            <div className="overlap-4">
              <img className="background-2" alt="Background" src="/img/background.svg" />
              <div className="content-3">
                <div className="element-8">
                  <div className="content-4">
                    <div className="heading-2">
                      <p className="text-wrapper-12">
                        Nous sélectionnons rigoureusement nos agriculteurs partenaires pour vous garantir des produits
                        de qualité.Nous vous proposons des produits 100% naturels, sans additifs ni conservateurs.
                      </p>
                      <div className="title">
                        <div className="text-wrapper-13">Pourquoi nous choisir ?</div>
                        <p className="text-wrapper-14">Nous n’achetons pas sur le marché ouvert et aux commerçants.</p>
                      </div>
                    </div>
                    <div className="points">
                      <div className="element-9">
                        <div className="title-wrapper">
                          <div className="title-2">
                            <div className="icon">
                              <div className="ellipse" />
                            </div>
                            <div className="text-wrapper-15">100% Naturel</div>
                          </div>
                        </div>
                        <div className="content-5">
                          <p className="text-wrapper-16">
                            Nous vous offrons des produits sains et savoureux, issus de l’agriculture biologique et
                            respectueux de l’environnement.
                          </p>
                        </div>
                      </div>
                      <div className="element-9">
                        <div className="title-wrapper">
                          <div className="title-2">
                            <div className="icon">
                              <div className="ellipse" />
                            </div>
                            <div className="text-wrapper-15">Augmente la résistance</div>
                          </div>
                        </div>
                        <div className="content-5">
                          <p className="text-wrapper-16">
                            Remplis de vitamines et de nutriments, nos produits vous apportent de l’énergie et du
                            bien-être. Nos produits renforcent votre système immunitaire et votre santé.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="image-2" alt="Image" src="/img/image-2.png" />
                </div>
                <div className="element-10">
                  <div className="element-11">
                    <div className="overlap-group-3">
                      <div className="text-wrapper-17">Retour avec étiquette imprimable.</div>
                      <div className="text-wrapper-18">Politique de retour</div>
                      <div className="icon-wrapper">
                        <img className="icon-2" alt="Icon" src="/img/icon-3.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="element-11">
                    <div className="overlap-group-3">
                      <p className="text-wrapper-17">
                        Livrer des produits frais, directement du producteur au consommateur.
                      </p>
                      <div className="text-wrapper-19">100% Frais</div>
                      <div className="icon-wrapper">
                        <img className="icon-3" alt="Icon" src="/img/icon-2.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="element-12">
                    <div className="overlap-5">
                      <div className="background-3" />
                      <p className="text-wrapper-17">Nous traitons vos demandes rapidement.</p>
                      <div className="text-wrapper-20">Support 24/7</div>
                      <div className="icon-wrapper">
                        <img className="icon-4" alt="Icon" src="/img/icon-1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="element-13">
                    <div className="overlap-group-3">
                      <div className="overlap-group-4">
                        <p className="text-wrapper-21">Nous vous garantissons un paiement sécurisé et confidentiel.</p>
                        <div className="text-wrapper-22">Paiement sécurisé</div>
                      </div>
                      <div className="icon-wrapper">
                        <img className="icon-5" alt="Icon" src="/img/icon.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="group-2" alt="Group" src="/img/group-3.png" />
              <img className="group-3" alt="Group" src="/img/group-2.png" />
            </div>
          </div>
          <img className="group-4" alt="Group" src="/img/group-1.png" />
          <div className="offer-banner">
            <div className="element-14">
              <div className="text-3">
                <div className="text-wrapper-23">Local !!</div>
                <p className="engagement-envers-le">
                  Engagement envers le soutien des agriculteurs locaux et la promotion d&#39;une alimentation saine.
                </p>
              </div>
            </div>
            <div className="element-15">
              <div className="text-4">
                <div className="text-wrapper-24">Avantages</div>
                <p className="text-wrapper-25">
                  Élimination des intermédiaires pour des prix plus justes et un accès facilité aux produits de qualité.
                </p>
              </div>
            </div>
          </div>
          <img className="group-5" alt="Group" src="/img/group.png" />
        </div>
        <img className="banner" alt="Banner" src="/img/banner.png" />
        <div className="header">
          <div className="header-2">
            <img className="logo-4" alt="Logo" src="/img/logo-2.png" />
            <div className="element-16">
              <img className="logo-5" alt="Logo" src="/img/logo-1.svg" />
              <div className="menu">
                <div className="overlap-group-5">
                  <p className="home-about-pages">
                    Home&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    About&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Pages&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shop&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Projects&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; News
                  </p>
                  <img className="icon-6" alt="Icon" src="/img/icon-5.png" />
                </div>
              </div>
            </div>
            <div className="element-17">
              <div className="search">
                <div className="search-icon">
                  <img className="vector-2" alt="Vector" src="/img/vector-1.svg" />
                </div>
              </div>
              <div className="cart">
                <div className="overlap-group-6">
                  <div className="text-wrapper-26">Cart (0)</div>
                  <div className="cart-icon">
                    <img className="icon-7" alt="Icon" src="/img/icon-4.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="logo-6" alt="Logo" src="/img/logo.svg" />
        <img className="shop" alt="Shop" src="/img/shop.png" />
      </div>
    </div>
  );
};
